/** =================== Manrope =================== **/

/** Manrope Extra Light **/
@font-face {
    font-family: "Manrope-ExtraLight";
    font-weight: 200;
    font-style: normal;
    src: url("Manrope-ExtraLight.ttf")
}

/** Manrope Light **/
@font-face {
    font-family: "Manrope-Light";
    font-weight: 300;
    font-style: normal;
    src: url("Manrope-Light.ttf")
}

/** Manrope Regular **/
@font-face {
    font-family: "Manrope-Regular";
    font-weight: 400;
    font-style: normal;
    src: url("Manrope-Regular.ttf")
}

/** Manrope Medium **/
@font-face {
    font-family: "Manrope-Medium";
    font-weight: 500;
    font-style: normal;
    src: url("Manrope-Medium.ttf")
}

/** Manrope Semi Bold **/
@font-face {
    font-family: "Manrope-SemiBold";
    font-weight: 600;
    font-style: normal;
    src: url("Manrope-SemiBold.ttf")
}

/** Manrope Bold **/
@font-face {
    font-family: "Manrope-Bold";
    font-weight: 700;
    font-style: normal;
    src: url("Manrope-Bold.ttf")
}

/** Manrope Extra Bold **/
@font-face {
    font-family: "Manrope-ExtraBold";
    font-weight: 800;
    font-style: normal;
    src: url("Manrope-ExtraBold.ttf")
}